<template>
  <div>
    <v-snackbar
      v-model="successSnackbar"
      :timeout="4000"
      top
      transition="scroll-y-transition"
      color="success"
    >
      Заявка № {{result.elementId}} на регистрацию под проект успешно создана!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="successSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      top
      :timeout="-1"
      color="error"
    >
      Ошибка ({{result}}) обратитесь к администратору!!!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="error"
      v-model="isValidationError"
      top
      right
    >
      Ошибка валидации формы, проверьте поля
    </v-snackbar>
    <div class="wrapper-form">
      <form @submit.prevent>
        <p class="title black--text">Информация по регистрации</p>
        <div class="wrapper-block">
          <CompanyInputSync
            v-model="customer"
            label="Компания-заказчик"
            :error-messages="customerNameErrors"
            :blur="$v.customer.title.$touch"
          />
          <v-text-field
            v-model.trim="individualNumber"
            label="ИНН (необязательно)"
            outlined
            dense
          >
          </v-text-field>
          <v-text-field
            v-model.trim="objectName"
            :error-messages="objectNameErrors"
            label="Тип объекта"
            outlined
            dense
            hint="Например: Конференц зал, Переговорная, СЦ и тд."
            @blur="$v.objectName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model.trim="customer.address"
            ref="objectAddress"
            :error-messages="objectAddressErrors"
            label="Адрес объекта"
            clearable
            outlined
            dense
            @blur="$v.customer.address.$touch()"
          ></v-text-field>
          <v-text-field
            v-model.trim="projectName"
            ref="projectName"
            :error-messages="projectNameErrors"
            label="Проект"
            outlined
            dense
            @input="$v.projectName.$touch()"
            @blur="$v.projectName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model.trim="projectManager"
            ref="quarterNumber"
            outlined
            dense
            label="Менеджер проекта (необязательно)"
          ></v-text-field>
          <v-radio-group
            v-model="quarterNumber"
            row
            :error-messages="quarterNumberErrors"
          >
            <template v-slot:label>
              <span class="subtitle-1">Квартал реализации</span>
            </template>
            <v-radio
              v-for="n in 5"
              :key="n"
              :label="n === 5 ? '-' : n.toString()"
              :value="n === 5 ? '-' : n"
            >
            </v-radio>
          </v-radio-group>
          <v-radio-group
            v-model="whatCompanyRegistrationSelect"
            row
          >
            <template v-slot:label>
              <span class="subtitle-1">Регистрация от компании</span>
            </template>
            <v-radio
              v-for="n in whatCompanyRegistrationItems"
              :key="n.value"
              :label="n.label"
              :value="n.value"
            >
            </v-radio>
          </v-radio-group>
          <v-textarea
            v-model.trim="description"
            ref="description"
            :error-messages="descriptionErrors"
            label="Описание регистрации"
            outlined
            dense
            auto-grow
            rows="4"
            hint="В описании укажите Наименование, Производителя, Код производителя. В случае необходимости прикрепите файл с данными полями"
            @input="$v.description.$touch()"
            @blur="$v.description.$touch()"
          ></v-textarea>
          <v-file-input
            v-model="files"
            multiple
            outlined
            dense
            id="test_file_input"
            label="Документы (необязательно)"
            counter
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
        <p class="title black--text">Дополнительная информация</p>
        <div class="wrapper-block">
          <DealInputSync
            v-model="deal"
            title="Сделка (опционально)"
          />
          <ProjectInput v-model="project"/>
          <TaskInput v-model="taskId"/>
          <add-observers v-model="observers"/>
        </div>
      </form>
      <v-btn
        class="mr-4 mb-4"
        color="primary"
        @click="submit"
        type="submit"
        :loading="loading"
      >
        Оставить заявку
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import ProjectInput from './app/ProjectInput'
  import TaskInput from './app/TaskInput'
  import CompanyInputSync from '@/components/CompanyInputSync'
  import DealInputSync from '@/components/DealInputSync'
  import AddObservers from '@/components/app/AddObservers'
  import { trimCoordinates } from '@/libs/main'
  import Bitrix from '@/plugins/Bitrix'

  export default {
    name: 'ProjectRegistrationForm',
    components: { AddObservers, DealInputSync, CompanyInputSync, TaskInput, ProjectInput },
    validations: {
      customer: {
        title: { required },
        address: { required }
      },
      objectName: { required },
      projectName: { required },
      quarterNumber: { required },
      description: { required }
    },

    data: () => ({
      customer: {
        id: '',
        title: '',
        address: ''
      },
      individualNumber: '',
      objectName: '',
      objectAddress: '',
      projectName: '',
      projectManager: '',
      quarterNumber: '',
      whatCompanyRegistrationSelect: 883,
      whatCompanyRegistrationItems: [
        {
          value: 883,
          label: 'PolyService'
        },
        {
          value: 885,
          label: 'Polymedia'
        }
      ],
      description: '',
      files: [],
      deal: null,
      project: null,
      taskId: '',
      observers: [],
      result: '',
      loading: false,
      successSnackbar: false,
      errorSnackbar: false,
      isValidationError: false
    }),
    computed: {
      ...mapGetters(['getDeal']),
      customerNameErrors () {
        const errors = []
        if (!this.$v.customer.title.$dirty) return errors
        !this.$v.customer.title.required && errors.push('Укажить компанию-заказчика')
        return errors
      },
      objectNameErrors () {
        const errors = []
        if (!this.$v.objectName.$dirty) return errors
        !this.$v.objectName.required && errors.push('Необходимо указать тип объекта')
        return errors
      },
      objectAddressErrors () {
        const errors = []
        if (!this.$v.customer.address.$dirty) return errors
        !this.$v.customer.address.required && errors.push('Необходимо указать адрес объекта')
        return errors
      },
      projectNameErrors () {
        const errors = []
        if (!this.$v.projectName.$dirty) return errors
        !this.$v.projectName.required && errors.push('Необходимо указать проект')
        return errors
      },
      quarterNumberErrors () {
        const errors = []
        if (!this.$v.quarterNumber.$dirty) return errors
        !this.$v.quarterNumber.required && errors.push('Необходимо выбрать одно из значений')
        return errors
      },
      descriptionErrors () {
        const errors = []
        if (!this.$v.description.$dirty) return errors
        !this.$v.description.required && errors.push('Необходимо добавить какое-либо описание')
        return errors
      }
    },
    watch: {
      'customer.address'(val) {
        val && (this.customer.address = trimCoordinates(val))
      },
      'customer.id'(val) {
        if (val) {
          Bitrix.callMethod('crm.requisite.list', {
            filter: {
              ENTITY_TYPE_ID: 4,
              ENTITY_ID: this.customer.id
            }
          }).then(res => {
            this.individualNumber = res[0]?.RQ_INN
          })
        }
        // val && (this.individualNumber = Bitrix.)
      }
    },
    methods: {
      ...mapActions(['addRegistrationRequest']),
      async submit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.isValidationError = true
          return false
        } else {
          this.$v.$reset()
          this.loading = true
          const formData = {
            customer: this.customer,
            individualNumber: this.individualNumber,
            objectName: this.objectName,
            projectName: this.projectName,
            projectManager: this.projectManager,
            quarterNumber: this.quarterNumber,
            whatCompanyRegistration: this.whatCompanyRegistrationSelect,
            description: this.description,
            files: this.files,
            deal: this.deal,
            taskId: this.taskId,
            observers: this.observers
          }
          const result = await this.addRegistrationRequest(formData)
          this.result = result
          this.loading = false
          if (result.elementId) {
            this.successSnackbar = true
            this.objectName = this.projectName = this.projectManager = this.quarterNumber = this.taskId = this.description = ''
            this.files = this.observers = []
          } else {
            this.errorSnackbar = true
          }
        }
      }
    },
    created() {
      this.customer.id = this.getDeal?.companyId
      if (this.getDeal.id) {
        Bitrix.callMethod('user.get', { ID: this.getDeal.assignedUserId })
          .then(res => {
            this.projectManager = `${res[0]?.LAST_NAME} ${res[0]?.NAME}`
          })
      }
    }
  }
</script>
